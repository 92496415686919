export const IS_DEBUG = process.env.NODE_ENV !== 'production'
//
// FIREBASE STORAGE
//
export const ENVIRONMENTS = { DEV: 'DEV', PROD: 'PROD' }
export const DEFAULT_ENV = ENVIRONMENTS.DEV

// DEV
export const FB_APIKEY_DEV = 'AIzaSyASqJA6pgRcboXXLO6Dvfdj9owDH1hCxd0'
export const FB_AUTH_DOMAIN_DEV = 'devnextnailscare.firebaseapp.com'
export const FB_DATABASE_URL_DEV = 'https://devnextnailscare.firebaseio.com'
export const FB_PROJECT_ID_DEV = 'devnextnailscare'
export const FB_STORAGE_BUCKET_DEV = 'devnextnailscare.appspot.com'
export const FB_MESSAGING_SENDER_ID_DEV = '966231829285'
export const FB_APP_ID_DEV = '1:966231829285:web:8ae1884ebfb32f8b8388cc'
export const FB_MEASUREMENT_ID_DEV = 'G-6Q8WNX7SGQ'
export const DATABASE_URL_DEV = ''
export const STORAGE_URL_DEV = ''

// PROD
export const FB_APIKEY_PROD = 'AIzaSyAOkhRH-fDkt5LksgIRH0ur5iZX3vJ6wu8'
export const FB_AUTH_DOMAIN_PROD = 'nextnails-care.firebaseapp.com'
export const FB_DATABASE_URL_PROD = 'https://nextnails-care.firebaseio.com'
export const FB_PROJECT_ID_PROD = 'nextnails-care'
export const FB_STORAGE_BUCKET_PROD = 'nextnails-care.appspot.com'
export const FB_MESSAGING_SENDER_ID_PROD = '775029824729'
export const FB_APP_ID_PROD = '1:775029824729:web:5b6bbcd947ba9b622172c8'
export const FB_MEASUREMENT_ID_PROD = 'G-25DEJ53Q9F'
export const DATABASE_URL_PROD = ''
export const STORAGE_URL_PROD = ''

// API Keys
export const GOOGLE_MAP_KEY = 'AIzaSyASqJA6pgRcboXXLO6Dvfdj9owDH1hCxd0'

//
// FIREBASE MODEL
//
export const FB_USERS = 'users'
export const FB_USER_BOOKINGS = 'userBookings'
export const FB_USER_SETTINGS = 'userSettings'
export const FB_SYSTEM_SERVICES = 'systemServices'
export const FB_SYSTEM_CATEGORIES = 'systemCategories'
export const FB_SYSTEM_BOOKINGS = 'systemBookings'
export const FB_SYSTEM_PLANS = 'systemPlans'
export const FB_SYSTEM_ADMINS = 'systemAdmins'
export const FB_STORES = 'stores'
export const FB_STORE_SERVICES = 'storeServices'
export const FB_STORE_CATEGORIES = 'storeCategories'
export const FB_STORE_EMPLOYEES = 'storeEmployees'
export const FB_STORE_BOOKINGS = 'storeBookings'
export const FB_STORE_PAYMENTS = 'storePayments'
export const FB_SETTINGS = 'settings'
export const FB_PUBLIC = 'public'

//
// FIREBASE FUNCS
//
export const FB_FUNC_GUEST_VIEW_STORES = 'guestViewStores'
export const FB_FUNC_GUEST_VIEW_STORE = 'guestViewStore'
export const FB_FUNC_GUEST_VIEW_EMPLOYEES = 'guestViewEmployees'
export const FB_FUNC_GUEST_VIEW_SERVICES = 'guestViewServices'
export const FB_FUNC_CUSTOMER_VIEW_STORE = 'customerViewStore'
export const FB_FUNC_CUSTOMER_VIEW_STORES = 'customerViewStores'
export const FB_FUNC_CUSTOMER_VIEW_SERVICE = 'customerViewService'
export const FB_FUNC_CUSTOMER_VIEW_EMPLOYEE = 'customerViewEmployee'
export const FB_FUNC_CUSTOMER_VIEW_EMPLOYEES = 'customerViewEmployees'
export const FB_FUNC_CUSTOMER_VIEW_SERVICES = 'customerViewServices'
export const FB_FUNC_CUSTOMER_VIEW_STORE_BOOKINGS = 'customerViewStoreBookings'
export const FB_FUNC_CUSTOMER_BOOK_SERVICE = 'customerBookService'
export const FB_FUNC_CUSTOMER_VIEW_BOOKING = 'customerViewBooking'
export const FB_FUNC_CUSTOMER_CANCEL_BOOKING = 'customerCancelBooking'
export const FB_FUNC_CUSTOMER_EDIT_BOOKING = 'customerEditBooking'
export const FB_FUNC_EMPLOYEE_BOOKINGS = 'employeeBookings'
export const FB_FUNC_EMPLOYEE_UPDATE_INFO = 'employeeUpdateInfo'
export const FB_FUNC_EMPLOYEE_GET_INFO = 'employeeGetInfo'
export const FB_FUNC_EMPLOYEE_ADD_BOOKING = 'employeeAddBooking'
export const FB_FUNC_EMPLOYEE_VIEW_BOOKING = 'employeeViewBooking'
export const FB_FUNC_EMPLOYEE_CANCEL_BOOKING = 'employeeCancelBooking'
export const FB_FUNC_EMPLOYEE_EDIT_BOOKING = 'employeeEditBooking'
export const FB_FUNC_EMPLOYEE_VIEW_EMPLOYEES = 'employeeViewEmployees'
export const FB_FUNC_EMPLOYEE_VIEW_SERVICES = 'employeeViewServices'
export const FB_FUNC_EMPLOYEE_VIEW_STORE = 'employeeViewStore'
export const FB_FUNC_EMPLOYEE_SEARCH_CUSTOMERS = 'employeeSearchCustomers'
export const FB_FUNC_EMPLOYEE_INVITE_CUSTOMER = 'employeeInviteCustomer'
export const FB_FUNC_STORE_ADD_BOOKING = 'storeAddBooking'
export const FB_FUNC_STORE_CANCEL_BOOKING = 'storeCancelBooking'
export const FB_FUNC_STORE_VIEW_BOOKING = 'storeViewBooking'
export const FB_FUNC_STORE_EDIT_BOOKING = 'storeEditBooking'
export const FB_FUNC_STORE_SEARCH_CUSTOMERS = 'storeSearchCustomers'
export const FB_FUNC_STORE_VIEW_CUSTOMER = 'storeViewCustomer'
export const FB_FUNC_STORE_EDIT_CUSTOMER = 'storeEditCustomer'
export const FB_FUNC_STORE_VIEW_CUSTOMER_SETTINGS = 'storeViewCustomerSettings'
export const FB_FUNC_STORE_EDIT_CUSTOMER_SETTINGS = 'storeEditCustomerSettings'
export const FB_FUNC_STORE_CREATE_CUSTOMER = 'storeCreateCustomer'
export const FB_FUNC_STORE_INVITE_CUSTOMER = 'storeInviteCustomer'
export const FB_FUNC_STORE_CREATE_EMPLOYEE = 'storeCreateEmployee'
export const FB_FUNC_STORE_DELETE_EMPLOYEE = 'storeDeleteEmployee'
export const FB_FUNC_STORE_DELETE_SERVICE = 'storeDeleteService'
export const FB_FUNC_SYSTEM_SEARCH_STORES = 'systemSearchStores'
export const FB_FUNC_SYSTEM_DELETE_STORE = 'systemDeleteStore'
export const FB_FUNC_SYSTEM_SEARCH_EMPLOYEES = 'systemSearchEmployees'
export const FB_FUNC_SYSTEM_CREATE_USER = 'systemCreateUser'
export const FB_FUNC_SYSTEM_GET_EMPLOYEE_INFO = 'systemGetEmployeeInfo'
export const FB_FUNC_SYSTEM_UPDATE_EMPLOYEE_INFO = 'systemUpdateEmployeeInfo'
export const FB_FUNC_PAY_PLAN = 'payPlan'
export const FB_FUNC_CANCEL_SUBSCRIPTION = 'cancelSubscription'
export const FB_FUNC_GENERATE_CLIENT_TOKEN = 'generateClientToken'

//
// APP MODEL
//
export const ID = 'id'
export const PARENT_ID = 'parentId'
export const KEY = 'key'
export const NAME = 'name'
export const EMAIL = 'email'
export const FIRST_NAME = 'firstName'
export const LAST_NAME = 'lastName'
export const PHONE = 'phone'
export const CITY = 'city'
export const STATE = 'state'
export const ZIP = 'zip'
export const ADDRESS = 'address'
export const ROLE = 'role'
export const AVATAR = 'avatar'
export const DATE = 'date'
export const DATE_PARSED = 'dateParsed'
export const DATE_TIME = 'dateTime'
export const DATE_MODIFIED = 'dateModified'
export const DATE_ADDED = 'dateAdded'
export const DATE_EDIT = 'dateEdit'
export const BIRTHDATE = 'birthdate'
export const DISPLAY_NAME = 'displayName'
export const JOB_TITLE = 'jobTitle'
export const HOBBIES = 'hobbies'
export const EMAIL_VIRIFIED = 'emailVerified'
export const USER_INFORMATION = 'userInformation'
export const CODE = 'code'
export const TITLE = 'title'
export const DESCRIPTION = 'description'
export const ESTIMATE = 'estimate'
export const STATUS = 'status'
export const DATE_STRING = 'dateString'
export const TIMESTAMP = 'timestamp'
export const YEAR = 'year'
export const MONTH = 'month'
export const DAY = 'day'
export const TYPE = 'type'
export const TERM_OF_USE = 'termOfUse'
export const PRIVACY_POLICY = 'privacyPolicy'
export const CATEGORY = 'category'
export const IMAGE = 'image'
export const PRICE = 'price'
export const COLOR = 'color'
export const DURATION = 'duration'
export const IS_SYSTEM = 'isSystem'
export const USER_ID = 'userId'
export const STORE_ID = 'storeId'
export const SERVICE_ID = 'serviceId'
export const EMPLOYEE_ID = 'employeeId'
export const CATEGORY_ID = 'categoryId'
export const EMPLOYEE_IDS = 'employeeIds'
export const SYSTEM_CATEGORY_ID = 'systemCategoryId'
export const SUCCESS = 'success'
export const START_TIME = 'startTime'
export const END_TIME = 'endTime'
export const WORKING_PERIOD = 'workingPeriod'
export const WORKING_OFFLINE = 'workingOffline'
export const WORKING_EDIT = 'workingEdit'
export const STORE = 'store'
export const SERVICE = 'service'
export const EMPLOYEE = 'employee'
export const EMPLOYEE_AUTO = 'employeeAuto'
export const EMPLOYEE_EDIT = 'employeeEdit'
export const USER = 'user'
export const DATE_OF_WEEK = 'dateOfWeek'
export const IS_CHECK = 'isCheck'
export const IS_MODIFIED = 'isModified'
export const HOUR_OF_DATE = 'hourOfDate'
export const HOURS = 'hours'
export const START = 'start'
export const START_PARSED = 'startParsed'
export const START_EDIT = 'startEdit'
export const START_INIT = 'startInit'
export const STATUS_OFFLINE = 'offline'
export const END = 'end'
export const END_PARSED = 'endParsed'
export const END_EDIT = 'endEdit'
export const SHIFT = 'shift'
export const NUMBER = 'number'
export const DISPLAY = 'display'
export const PATH = 'path'
export const LOCATION_LAT = 'locationLat'
export const LOCATION_LNG = 'locationLng'
export const LOCATION_ZOOM = 'locationZoom'
export const DEFAULT_AVATAR = 'defaultAvatar'
export const DEFAULT_SERVICE_IMAGE = 'defaultServiceImage'
export const DEFAULT_STORE_IMAGE = 'defaultStoreImage'
export const AUTH = 'auth'
export const REDIRECT = 'redirect'
export const TOTAL = 'total'
export const APPOINTMENT_CHOOSE_DATE = 'appointmentChooseDate'
export const APPOINTMENT_CHOOSE_EMPLOYEE = 'appointmentChooseEmployee'
export const APPOINTMENT_DRAGGABLE_ITEMS = 'appointmentDraggableItems'
export const APPOINTMENT_UPDATED_ITEMS = 'appointmentUpdatedItems'
export const ORDER = 'order'
export const BOOKING = 'booking'
export const ITEMS = 'items'
export const REMIND_24_BEFORE = 'notify24before'
export const REMIND_CANCEL = 'notifyCancel'
export const RESOURCE_ID = 'resourceId'
export const RESOURCE_TITLE = 'resourceTitle'
export const RESOURCE_IMAGE = 'resourceImage'
export const FEATURES = 'features'
export const HAS_SUBSCRIPTION = 'hasSubscription'
export const PAYING = 'paying'
export const EMPLOYEE_ORDERS = 'employeeOrders'
export const LOGO_URL = 'logoUrl'
export const LOGO_TEXT = 'logoText'
export const FOR_CUSTOMER = 'forCustomer'
export const NOTIFICATION_TIME = 'notificationTime'
export const TIME_ZOME = 'timeZone'

// Storage
export const CACHE_STORES = 'cacheStores'
export const CACHE_STORE_INFO = 'cacheStoreInfo'
export const CACHE_STORE_SERVCIES = 'cacheStoreServices'
export const CACHE_STORE_BOOKINGS = 'cacheStoreBookings'
export const CACHE_STORE_EMPLOYEES = 'cacheStoreEmployees'
export const CACHE_USER_BOOKINGS = 'cacheUserBookings'
export const CACHE_BOOK_TIME = 'cacheBookTime'
export const CACHE_SELECT_SERVICE = 'cacheSelectService'
export const CACHE_SELECT_SERVICE_FOR = 'cacheSelectServiceFor'
export const CACHE_CUSTOMER_NAME = 'cacheCustomerName'

//
// APP PAGES
//
export const PAGES_HOME = '/'
export const PAGES_LOGIN = '/login'
export const PAGES_ABOUT = '/about'
export const PAGES_CHANGE_PASSWORD = '/changePassword'
export const PAGES_FORGOT_PASSWORD = '/forgotPassword'
export const PAGES_REGISTER_CUSTOMER = '/registerCustomer'
export const PAGES_REGISTER_ADMIN = '/registerAdmin'
export const PAGES_CREATE_STORE = '/storeCreate'
export const PAGES_SELECT_PLAN = '/selectPlan'
export const PAGES_TERM_OF_USE = '/termOfUse'
export const PAGES_PRIVACY_POLICY = '/privacyPolicy'
export const PAGES_GUEST_APPOINTMENT_ABOUT_STORE = '/guestAboutStore'
export const PAGES_GUEST_APPOINTMENT_VIEW_SERVICES = '/guestViewServices'
export const PAGES_CUSTOMER_PROFILE = '/customerProfile'
export const PAGES_CUSTOMER_DASHBOARD = '/customerDashboard'
export const PAGES_CUSTOMER_SETTINGS = '/customerSettings'
export const PAGES_CUSTOMER_APPOINTMENTS = '/customerAppointments'
export const PAGES_CUSTOMER_APPOINTMENT = '/customerAppointment'
export const PAGES_CUSTOMER_MULTI_APPOINTMENT_VIEW_STORES = '/customerMultiAppointmentViewStores'
export const PAGES_CUSTOMER_MULTI_APPOINTMENT_VIEW_SERVICES = '/customerMultiAppointmentViewServices'
export const PAGES_CUSTOMER_MULTI_APPOINTMENT_SCHEDULE_NOW = '/customerMultiAppointmentScheduleNow'
export const PAGES_CUSTOMER_MULTI_APPOINTMENT_ABOUT_STORE = '/customerMultiAppointmentAboutStore'
export const PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_APPOINTMENT = '/customerAddAppointmentViewAppointment'
export const PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_STORES = '/customerAddAppointmentViewStores'
export const PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_SERVICES = '/customerAddAppointmentViewServices'
export const PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_EMPLOYEES = '/customerAddAppointmentViewEmployees'
export const PAGES_CUSTOMER_ADD_APPOINTMENT_VIEW_TIMES = '/customerAddAppointmentViewTimes'
export const PAGES_CUSTOMER_ADD_APPOINTMENT_SCHEDULE_NOW = '/customerAddAppointmentScheduleNow'
export const PAGES_CUSTOMER_EDIT_APPOINTMENT_VIEW_SERVICES = '/customerEditAppointmentViewServices'
export const PAGES_CUSTOMER_EDIT_APPOINTMENT_SCHEDULE_NOW = '/customerEditAppointmentScheduleNow'
export const PAGES_EMPLOYEE_PROFILE = '/employeeProfile'
export const PAGES_EMPLOYEE_DASHBOARD = '/employeeDashboard'
export const PAGES_EMPLOYEE_APPOINTMENTS = '/employeeAppointments'
export const PAGES_EMPLOYEE_APPOINTMENT = '/employeeAppointment'
export const PAGES_EMPLOYEE_ADD_APPOINTMENT_VIEW_APPOINTMENT = '/employeeAddAppointmentViewAppointment'
export const PAGES_EMPLOYEE_ADD_APPOINTMENT_VIEW_CUSTOMERS = '/employeeAddAppointmentViewCustomers'
export const PAGES_EMPLOYEE_ADD_APPOINTMENT_VIEW_SERVICES = '/employeeAddAppointmentViewServices'
export const PAGES_EMPLOYEE_ADD_APPOINTMENT_VIEW_TIMES = '/employeeAddAppointmentViewTimes'
export const PAGES_EMPLOYEE_ADD_APPOINTMENT_SCHEDULE_NOW = '/employeeAddAppointmentScheduleNow'
export const PAGES_EMPLOYEE_EDIT_APPOINTMENT_VIEW_SERVICES = '/employeeEditAppointmentViewServices'
export const PAGES_EMPLOYEE_EDIT_APPOINTMENT_SCHEDULE_NOW = '/employeeEditAppointmentScheduleNow'
export const PAGES_EMPLOYEE_INVITE_APPOINTMENT_INVITE_CUSTOMER = '/employeeInviteAppointmentInviteCustomer'
export const PAGES_STORE_DASHBOARD = '/storeDashboard'
export const PAGES_STORE_VIEW = '/storeView'
export const PAGES_STORE_EDIT = '/storeEdit'
export const PAGES_STORE_PROFILE = '/storeProfile'
export const PAGES_STORE_VIEW_CUSTOMERS = '/storeViewCustomers'
export const PAGES_STORE_VIEW_CUSTOMER_PROFILE = '/storeViewCustomerProfile'
export const PAGES_STORE_VIEW_CUSTOMER_HISTORY = '/storeViewCustomerHistory'
export const PAGES_STORE_VIEW_EMPLOYEES = '/storeViewEmployees'
export const PAGES_STORE_ADD_EMPLOYEE = '/storeAddEmployee'
export const PAGES_STORE_EDIT_EMPLOYEE = '/storeEditEmployee'
export const PAGES_STORE_EDIT_SERVICE = '/storeServiceEdit'
export const PAGES_STORE_ADD_SERVICE = '/storeServiceAdd'
export const PAGES_STORE_VIEW_SERVICES = '/storeServices'
export const PAGES_STORE_ADD_CATEGORY = '/storeAddCategory'
export const PAGES_STORE_EDIT_CATEGORY = '/storeEditCategory'
export const PAGES_STORE_VIEW_CATEGORIES = '/storeViewCategories'
export const PAGES_STORE_APPOINTMENTS = '/storeAppointments'
export const PAGES_STORE_APPOINTMENTS_SINGLE = '/storeAppointmentsSingle'
export const PAGES_STORE_APPOINTMENT = '/storeAppointment'
export const PAGES_STORE_EMPLOYEE_SCHEDULE = '/storeEmployeeSchedule'
export const PAGES_STORE_ADD_APPOINTMENT_VIEW_APPOINTMENT = '/storeAddAppointmentViewAppointment'
export const PAGES_STORE_ADD_APPOINTMENT_VIEW_CUSTOMERS = '/storeAddAppointmentViewCustomers'
export const PAGES_STORE_ADD_APPOINTMENT_VIEW_SERVICES = '/storeAddAppointmentViewServices'
export const PAGES_STORE_ADD_APPOINTMENT_VIEW_EMPLOYEES = '/storeAddAppointmentViewEmployees'
export const PAGES_STORE_ADD_APPOINTMENT_SCHEDULE_NOW = '/storeAddAppointmentScheduleNow'
export const PAGES_STORE_ADD_APPOINTMENT_VIEW_TIMES = '/storeAddAppointmentViewTimes'
export const PAGES_STORE_EDIT_APPOINTMENT_VIEW_SERVICES = '/storeEditAppointmentViewServices'
export const PAGES_STORE_EDIT_APPOINTMENT_SCHEDULE_NOW = '/storeEditAppointmentScheduleNow'
export const PAGES_STORE_INVITE_APPOINTMENT_INVITE_CUSTOMER = '/storeInviteAppointmentInviteCustomer'
export const PAGES_STORE_SETTINGS = '/storeSettings'
export const PAGES_SYSTEM_DASHBOARD = '/systemDashboard'
export const PAGES_SYSTEM_ADD_STORE = '/systemAddStore'
export const PAGES_SYSTEM_EDIT_STORE = '/systemEditStore'
export const PAGES_SYSTEM_VIEW_STORES = '/systemViewStores'
export const PAGES_SYSTEM_VIEW_EMPLOYEES = '/systemViewEmployees'
export const PAGES_SYSTEM_ADD_EMPLOYEE = '/systemAddEmployee'
export const PAGES_SYSTEM_EDIT_EMPLOYEE = '/systemEditEmployee'
export const PAGES_SYSTEM_ADD_SERVICE = '/systemAddService'
export const PAGES_SYSTEM_EDIT_SERVICE = '/systemEditService'
export const PAGES_SYSTEM_VIEW_SERVICES = '/systemViewServices'
export const PAGES_SYSTEM_ADD_CATEGORY = '/systemAddCategory'
export const PAGES_SYSTEM_EDIT_CATEGORY = '/systemEditCategory'
export const PAGES_SYSTEM_VIEW_CATEGORIES = '/systemViewCategories'
export const PAGES_SYSTEM_VIEW_STORE_SERVICES = '/systemViewStoreServices'
export const PAGES_SYSTEM_ADD_STORE_SERVICE = '/systemAddStoreService'
export const PAGES_SYSTEM_EDIT_STORE_SERVICE = '/systemEditStoreService'
export const PAGES_PAYMENT = '/payment'
export const PAGES_SUBSCRIPTION = '/subscription'

//
// ROLE
//
export const ROLE_GUEST = 'guest'
export const ROLE_CUSTOMER = 'customer'
export const ROLE_EMPLOYEE_STORE = 'employee_store'
export const ROLE_ADMIN_STORE = 'admin_store'


//
// APP VALUES
//
export const CLICK_DELAY = 200 // 0.2s
export const LOAD_DELAY = 1000 // 1s
export const TOAST_INTERVAL = 2000 // 1s
export const TOAST_INTERVAL_LONG = 5000 // 5s
export const SCROLL_CHANGED_DELAY = 1000 // 1s
export const TRACK_LOAD_FILE_DELAY = 1000 // 1s
export const MAX_AVATAR_WIDTH = 500
export const MAX_PICTURE_WIDTH = 720
export const SUPPORT_IMAGE_TYPES = ['.png', '.jpg', '.heic', '.jpeg', '.tiff']
export const SUPPORT_VIDEO_TYPES = ['.mp3', '.mp4']

export const FORMAT = {
    DATE: 'YYYY-MM-DD', MONTH_NAME: 'MMM', TIME: 'HH:mm:ss', TIME_24: 'hh:mm A',
    EVENT_DATE: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]', DATE_SHOW: 'MM/DD/YYYY', MONTH_SHOW: 'MM/YYYY', DATETIME_SHOW: 'MM/DD/YYYY hh:mm a'
}

export const TIME = { second: 1, minute: 1 * 60, hour: 1 * 60 * 60, day: 1 * 60 * 60 * 24, week: 1 * 60 * 60 * 24 * 7 }

export const MESSAGE_ARCHIVED_TIME = 30 * 60 * 60 * 24 * 1000 // 30 days in millis

export const IMAGES_PATH = '/images'
export const AUDIOS_PATH = '/audios'

export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export const EMPLOYEE_DEFAULT_PASSWORD = '@123456'
export const EMPLOYEE_ANY_ONE_AVAILABLE = 'anyOneAvailable'
export const STORE_DEFAULT_LOCATION_LAT = 40.6917158
export const STORE_DEFAULT_LOCATION_LNG = -74.0954777
export const STORE_DEFAULT_ZOOM = 10
export const APPOINTMENT_NO_AVAILABLE_TIME = 'appointmentNoAvailableTime'
export const APPOINTMENT_NO_PROVIDER_AVAIALBLE = 'appointmentNoProviderAvailable'
export const APPOINTMENT_IS_SELECT_ANY_PROVIDER = 'selectAnyProvider'
export const APPOINTMENT_CUSTOMER_BOOKED_COLOR = '#FF0000'
export const APPOINTMENT_CUSTOMER_BOOKED_COLOR_ANY_PROVIDER = '#008000'
export const APPOINTMENT_TIME_SLOT_UNAVAILABLE_COLOR = '#efefef'
export const APPOINTMENT_FORMATS = {
    timeGutterFormat: 'h A', eventTimeRangeFormat: ({ start, end }, culture, local) => local.format(start, 'hh:mm A', culture)
}
export const CUSTOMER_FOR_MYSELF = 'Myself'

export const SMS_DEFAULT_PHONE = '5033828589'

export const CALENDAR_START_HOUR = 6 // 6 am
export const CALENDAR_END_HOUR = 21 // 10 pm
export const CALENDAR_START_MINUTE = 0 // 0 minutes
export const CALENDAR_END_MINUTE = 59 // 59 minutes
export const CALENDAR_TIME_STEP = 5 // 15 minutes
export const CALENDAR_TIME_SLOTS = 12 // Slots in a section
export const CALENDAR_TIME_AVAILABLE_STEP = 30 // 30 minutes
export const CALENDAR_SLOT_HEIGHT = 15
export const WORKING_MORNING_START = 6 // 6 am
export const WORKING_MORNING_END = 12 // 12 pm
export const WORKING_STEP = 4 // 4 hrs

export const MONDAY = { name: 'MONDAY', number: 1 }
export const TUESDAY = { name: 'TUESDAY', number: 2 }
export const WEDNESDAY = { name: 'WEDNESDAY', number: 3 }
export const THURSDAY = { name: 'THURSDAY', number: 4 }
export const FRIDAY = { name: 'FRIDAY', number: 5 }
export const SATURDAY = { name: 'SATURDAY', number: 6 }
export const SUNDAY = { name: 'SUNDAY', number: 0 }

export const STATUS_ACTIVE = 'Active'
export const STATUS_CANCELED = 'Canceled'